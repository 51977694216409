<template>
  <div class="fragment">
    <CusPaymentsTableAllUser
            :userTabs="navTabs"
            :filterGetParams="filterGetParams"
            :typeShops="typeShops"
            :countFilterParams="countFilterParams"
            @changeTab="changeTab"
            @filter="filter"
            @changeShopType="changeShopType"
            @changeFilter="changeFilter"
            @showMore="showMore"
            @paginate="paginate"
            @changePerPage="changePerPage"
            @resetFilter="resetFilter"
            @downloadFiles="downloadFiles"
    />
<!--    <CusPaymentsTableAdmin-->
<!--            v-if="$store.getters.getCurrentUserRights.additionalPermissions &&-->
<!--                $store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"-->
<!--            :userTabs="navTabs"-->
<!--            :filterGetParams="filterGetParams"-->
<!--            :typeShops="typeShops"-->
<!--            :countFilterParams="countFilterParams"-->
<!--            @changeTab="changeTab"-->
<!--            @filter="filter"-->
<!--            @changeShopType="changeShopType"-->
<!--            @changeFilter="changeFilter"-->
<!--            @showMore="showMore"-->
<!--            @paginate="paginate"-->
<!--            @changePerPage="changePerPage"-->
<!--            @resetFilter="resetFilter"-->
<!--            @downloadFiles="downloadFiles"-->
<!--    />-->
<!--    <CusPaymentsTableUser-->
<!--            v-if="$store.getters.getCurrentUserRights.additionalPermissions &&-->
<!--                !$store.getters.getCurrentUserRights.additionalPermissions[PERMISSIONS.VIEW_ADMIN_ROUTE_PREFIX]"-->
<!--            :userTabs="navTabs"-->
<!--            :filterGetParams="filterGetParams"-->
<!--            :typeShops="typeShops"-->
<!--            :countFilterParams="countFilterParams"-->
<!--            @changeTab="changeTab"-->
<!--            @filter="filter"-->
<!--            @changeShopType="changeShopType"-->
<!--            @changeFilter="changeFilter"-->
<!--            @showMore="showMore"-->
<!--            @paginate="paginate"-->
<!--            @changePerPage="changePerPage"-->
<!--            @resetFilter="resetFilter"-->
<!--            @downloadFiles="downloadFiles"-->
<!--    />-->
  </div>
</template>

<script>
  // import CusPaymentsTableUser from "./CusPaymentsTableUser/CusPaymentsTableUser.vue";
  // import CusPaymentsTableAdmin from "./CusPaymentsTableAdmin/CusPaymentsTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {shopDetails} from "../../../../../mixins/shopMixins/shopTypeFunc";
  import CusPaymentsTableAllUser
    from "@/components/modules/CusPaymentsModule/components/CusPaymentsTablePage/CusPaymentsTableAllUser/CusPaymentsTableAllUser";

  export default {
    name: "CusPaymentsTable",
    components: {
      CusPaymentsTableAllUser
      // CusPaymentsTableUser,
      // CusPaymentsTableAdmin,
    },

    mixins: [shopDetails, routeFilter],

    watch: {
      downloadPermissions() {
        this.checkTabPermission()
      }
    },

    data() {
      return {
        forPage: this.$store.getters.getCusPaymentsForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterOrder: '',
        filterType: '',
        filterUserId: '',
        filterUserName: '',
        filterRecipientName: '',
        filterRecipientEmail: '',
        filterTransactionNumber: '',
        filterTotal: '',
        filterTotalAmount: '',
        filterTrackingNum: '',
        filterItems: '',
        filterShop: '',
        filterComment: '',

        filterGetParams: {},
        countFilterParams: 0,
        typeShops: 0,

        navTabs:{
          paypal: {
            name: 'PayPal',
            value: 'paypal',
            id: 1,
            permissionVisible: false,
            active: true
          },
          authorize: {
            name: 'Authorize',
            value: 'authorize',
            id: 2,
            permissionVisible: false,
            active: false
          },
          braintree: {
            name: 'Braintree',
            value: 'braintree',
            id: 2,
            permissionVisible: false,
            active: false
          },
          other: {
            name: this.$t('cuspayments_other.localization_value.value'),
            value: 'other',
            id: 3,
            permissionVisible: false,
            active: false
          },
          balanceOutput: {
            name: this.$t('cuspayments_balanceOutput.localization_value.value'),
            id: 3,
            permissionVisible: false,
            value: 'balanceOutput',
            active: false
          },
          heartysan: {
            name: this.$t('cuspayments_heartysan.localization_value.value'),
            id: 3,
            permissionVisible: true,
            value: 'heartysan',
            active: false
          },
          unassigned: {
            name: this.$t('cuspayments_unassigned.localization_value.value'),
            id: 3,
            permissionVisible: false,
            active: false
          },
        },

      }
    },

    created() {
      if (this.getCookie('perPageTransactions')) {
        this.$store.commit('setCusPaymentsForPage', +this.getCookie('perPageTransactions'))
        this.forPage = this.$store.getters.getCusPaymentsForPage
      }

      this.$store.dispatch('fetchShopsTypes').then(() => {
        this.shopTypeFunc()
        this.typeShops = this.$store.getters.getShopTypesParent
        this.typeShops.pop()
        this.typeShops = this.typeShops.concat(this.$store.getters.getShopTypesChildWithActive)
      })


      if(this.$route.query.type !== undefined){
        this.navTabs.paypal.active = false
        this.navTabs[this.$route.query.type].active = true
      }

      if(this.downloadPermissions){
        this.checkTabPermission()
      }


    },

    methods: {

      checkTabPermission() {

        this.activeTabByPermissions('navTabs', {
            'paypal': this.PERMISSIONS.CUSTOMER_PAYMENT_PAYPAL,
            'authorize': this.PERMISSIONS.CUSTOMER_PAYMENT_AUTHORIZE,
            'braintree': this.PERMISSIONS.CUSTOMER_PAYMENT_BRAINTREE,
            'balanceOutput': this.PERMISSIONS.CUSTOMER_PAYMENTS_OUTPUT,
            'other': this.PERMISSIONS.OTHER_PAYMENT_MANAGE_GENERAL,
          })

        if(!this._.find(this.navTabs, {active: true}).permissionVisible){
          this.setActivePermissionVisibleTab('navTabs')
          return
        }

        this.filter()
      },


      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          pagination: this.$route.query.pagination,
          type: this.$route.query.type,
          date: this.$route.query.date,
          order: this.$route.query.order,
          userName: this.$route.query.userName,
          recipientName: this.$route.query.recipientName,
          recipientEmail: this.$route.query.recipientEmail,
          userId: this.$route.query.userId,
          transactionNumber: this.$route.query.transactionNumber,
          total: this.$route.query.total,
          totalAmount: this.$route.query.totalAmount,
          trackingNum: this.$route.query.trackingNum,
          items: this.$route.query.items,
          shop: this.$route.query.shop,
          comment: this.$route.query.comment,
        }
      },

      // changeFilter(data) {
      //   data.page = this.onePage
      //   data.count = this.forPage
      //
      //   this.getFilterParam()
      //   this.changeRouteParams(this.filterGetParams, data)
      //
      //   this.filter()
      // },

      changeShopType(newIndex) {
        this.typeShops.map((item, index) => {
          this.typeShops[index].activeTab = false

          if(index === newIndex)
            this.typeShops[index].activeTab = true
        })

        if(newIndex === 0){
          this.changeFilter({shop: ''})
        } else {
          this.changeFilter({shop: newIndex})
        }
      },

      // changeTab(id) {
      //   Object.keys(this.navTabs).map((item) => {
      //     this.navTabs[item].active = false
      //     if(item === id){
      //       this.navTabs[item].active = true
      //     }
      //   })
      //   this.changeFilter({type: id})
      // },

      // resetFilter() {
      //   this.$router.push({path: '', query: {}})
      //   this.changeTab(this.navTabs.paypal.value)
      // },

      changePerPage(val) {
        this.$store.commit('setCusPaymentsForPage', val)
        this.forPage = this.$store.getters.getCusPaymentsForPage

        if (this.$route.query.page > Math.ceil(this.$store.getters.getCusPaymentsCommonList.total / this.$store.getters.getCusPaymentsForPage)) {
          this.filterGetParams.page = Math.ceil(this.$store.getters.getCusPaymentsCommonList.total / this.$store.getters.getCusPaymentsForPage)
          this.$router.replace({name: '', query: {...this.$route.query, page: this.filterGetParams.page}})
        }

        this.filter(false)
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        let paginationFlag = false
        if (this.$route.query?.pagination === 'true') {
          paginationFlag = true
        }

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this, paginationFlag)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        // True if get next page
        if (next) this.$store.commit('setNextCusPaymentsPage', true)

        // save filter params in store
        this.$store.commit('setCusPaymentsFilter', window.location.search)

        let type = this.filterType !== '' ? this.filterType : 'paypal'

        if(this.navTabs.paypal.active){
          type = 'paypal'
        }

        if(this.navTabs.authorize.active){
          type = 'authorize'
        }

        if(this.navTabs.balanceOutput.active){
          type = 'other'
        }

        if(this.navTabs.braintree.active){
          type = 'braintree'
        }

        // if(this.navTabs.heartysan.active){
        //   type = 'other'
        // }

        this.$store.dispatch('fetchCusPayments', {type: type, filter: url}).then(() => {
          // skip get next page
          this.$store.commit('setNextCusPaymentsPage', false)
        })

        this.checkCountFilter(['type', 'userName'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        /**
         * Enable related order instances
         */
        if(this.navTabs.paypal.active || this.navTabs.authorize.active || this.navTabs.braintree.active){
          myQuery.appends('orderInstance')
        }

        if (this.filterOrder !== '') myQuery.where('PaymentId', this.filterOrder)
        if (this.filterTransactionNumber !== '') myQuery.where('PaymentTransactionId', this.filterTransactionNumber)
        if (this.filterTotal !== '') myQuery.where('PaymentTransactionUserAmount', this.filterTotal)
        if (this.filterTotalAmount !== '') myQuery.where('PaymentTransactionPaymentTotal', this.filterTotalAmount)
        if (this.filterTrackingNum !== '') myQuery.where('PaymentTransactionTrackingNumber', this.filterTrackingNum)
        if (this.filterRecipientName !== '') myQuery.where(this.navTabs.paypal.active ? 'buyer_name' : 'buyer_name', this.filterRecipientName)
        if (this.filterRecipientEmail !== '') myQuery.where('buyer_email', this.filterRecipientEmail)
        if (this.filterUserId !== '') myQuery.where(this.navTabs.paypal.active ? 'PaymentTransactionUserId' : 'PaymentTransactionUserId', this.filterUserId)
        if (this.filterItems !== '') myQuery.where('paymentTransactionDetail.items', this.filterItems)
        if (this.filterShop !== '') myQuery.where('shop_type', this.filterShop)
        if (this.filterComment !== '') myQuery.where('comment', this.filterComment)

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        if(this.navTabs.other.active){
          myQuery.where('PaymentTransactionStatus', 'success,on_hold')
        }

        if(this.navTabs.balanceOutput.active){
          myQuery.where('PaymentTransactionStatus', 'output')
        }

        if(this.navTabs.heartysan.active){
          myQuery.where('PaymentTransactionStatus', 'hearty_deal')
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      // showMore() {
      //   this.generateNextPAgeUrl(this)
      //
      //   this.filter(true)
      // },

      // checkCountFilter(withoutParams = false) {
      //   this.countFilterParams = this.checkCountFilterFromUrl(this, withoutParams)
      // },

      downloadFiles(fileType) {
        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage)

        let type = this.filterType !== '' ? this.filterType : 'paypal'

        if(this.navTabs.balanceOutput.active){
          type = 'other'
        }

        let EXPORT_ACTION_NAME = 'getExportCusPayments'
        if(this.navTabs.other.active){
          EXPORT_ACTION_NAME = 'getExportNewPayments'
        }

        this.$store.dispatch(EXPORT_ACTION_NAME, {filter: url, transactionType: type, type: fileType}).then((response) => {
          if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            if(fileType !== 'csv' && fileType !== 'xlsx') {
              this.openNotify('success', 'common_notificationExportRequest')
              return
            }

            this.createDownloadLink({data: response.data, name: `Customers payments.${fileType}`, type: fileType})
          }
          else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        }).catch(error => this.createErrorLog(error))

        // this.$store.dispatch(EXPORT_ACTION_NAME, {filter: url, type: type, exportType: fileType}).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     const blob = new Blob([response.data], {type: `application/${fileType}`})
        //     const link = document.createElement('a')
        //     link.href = URL.createObjectURL(blob)
        //     link.download = `Customers payments.${fileType}`
        //     link.click()
        //     URL.revokeObjectURL(link.href)
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('error', 'common_notificationUndefinedError')
        //     }, 200)
        //   }
        // }).catch(error => this.createErrorLog(error))

      },

    },

    destroyed() {
      setTimeout(() => {
        this.$store.commit('setCusPayments', {cusPayments: []})
        this.$store.commit('setCusPaymentsCommonList', {cusPaymentsCommonList: []})
      }, 300)
    },

  }
</script>

<style scoped>

</style>
